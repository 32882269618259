<template>
   <div>
    <div class="popup">
      <van-popup :show="show_popup2" round="20">
        <div style="width: 4rem;height: 5rem;border-radius: .3rem;overflow: hidden;display: flex;flex-direction: column;justify-content: center;padding: 10px;">
            <div style="text-align: center;">
                <van-loading type="spinner" color="#1989fa" size="40" />
                <div style="color: #1989fa;font-size: 16px;">订单正在加载中，请勿退出...</div>
            </div>
          
        </div>

      </van-popup>
    </div>
    <iframe id="ifa" :src="url" frameborder="0" style="width: 100vw;height: 100vh;"></iframe>
   </div>
</template>

<script>
    export default {
        data(){
            return{
                url:'',
                show_popup2:true
            }
        },
        mounted(){
            // 创建一个新的iframe元素
            if(this.getQueryString('url')){
                let that=this
            var iframe = document.getElementById("ifa");
            console.log(this.getQueryString('url'))
            iframe.src = this.getQueryString('url');

            // 判断浏览器类型并注册相应的事件
            if (iframe.attachEvent) {
            // IE浏览器使用attachEvent方法
                iframe.attachEvent("onload", function() {
                    that.show_popup2=false
                });
            } else {
            // 非IE浏览器使用onload事件
                iframe.onload = function() {
                    that.show_popup2=false
                };
            }

            }
            
        },
        methods:{
            getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return "";
    },
        }
    }
</script>

<style lang="less" scoped>

</style>