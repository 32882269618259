import { createRouter, createWebHashHistory } from 'vue-router'

import iframePage from '@/views/iframePage.vue' 
const routes = [
  {
    path: '/',
    name: 'home',
    component: iframePage
  },
  {
    path: '/ifa',
    name: 'ifa',
    component: iframePage
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
